var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-12"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header"},[_c('h4',{staticClass:"card-header-title"},[_vm._v(_vm._s(_vm.$t('components.suppliers.purchaseInstallments')))]),(
          _vm.$parent.item.stat == 1 &&
          _vm.$site.purchasePayments_allow &&
          (_vm.$user.admin ||
            _vm.$user.role.purchase_payments_add)
        )?_c('router-link',{staticClass:"btn btn-sm btn-primary",attrs:{"title":"$t('components.suppliers.addPremium')","to":'/purchaseInstallments/create?supplier_id=' + _vm.$route.params.id}},[_c('i',{staticClass:"fas fa-plus"}),_vm._v(" "+_vm._s(_vm.$t('components.suppliers.addition'))+" ")]):_vm._e()],1),_c('indexTable',{attrs:{"searchText":_vm.$t('components.suppliers.searchForPremium'),"withoutCard":true,"emptyTableText":_vm.$t('purchaseCheques.thereAreNopurchaseCheques'),"filter":{supplier_id: _vm.$route.params.id},"emptyTableSubText":_vm.$t('allerts.TryAddingSomeItemsToTheTableAndTryAgain'),"cloumns":[
        { column: 'code', title: _vm.$t('components.suppliers.Paid'), type: 'mainLink', sort: true },
        {
          column: 'supplier_id',
          title: _vm.$t('components.suppliers.supplierName'),
          type: 'link',
          to: 'supplier',
          sort: true,
          link: true,
        },
        {
          column: 'created_date',
          title: _vm.$t('components.suppliers.dateRegistration'),
          type: 'text',
          sort: true,
        },
        {
          column: 'date',
          title: _vm.$t('purchaseCheques.paymentDate'),
          type: 'text',
          sort: true,
        },
        {
          column: 'description',
          title: _vm.$t('purchaseCheques.thatsAbout'),
          type: 'text',
          sort: true,
        },
        {
          column: 'cost',
          title: _vm.$t('purchaseCheques.amount'),
          type: 'text',
          sort: true,
        },
        {
          column: 'payment_method_id',
          title: _vm.$t('components.suppliers.paymentMethod'),
          type: 'link',
          to: 'paymentMethod',
          sort: true,
          link: false,
        },
        {
          column: 'stat',
          title: _vm.$t('components.suppliers.theCondition'),
          type: 'status',
          sort: true,
          values: [
            { title: _vm.$t('components.suppliers.pending'), value: 0, color: 'info' },
            { title: _vm.$t('components.suppliers.driven'), value: 1, color: 'success' } ],
        },
        {
          column: 'options',
          title: _vm.$t('purchaseCheques.settings'),
          type: 'options',
          options: [
            { name: 'show' },
            {
              name: 'edit',
              role:
                _vm.$user.admin ||
                _vm.$user.role.purchase_cheques_edit,
            },
            {
              name: 'pay',
              role:
                _vm.$user.admin ||
                _vm.$user.role.purchase_cheques_edit,
            },
            {
              name: 'delete',
              role:
                _vm.$user.admin ||
                _vm.$user.role.purchase_cheques_delete,
            } ],
        } ],"deleteText":{
        attention: _vm.$t('allerts.Attention'),
        areYouReallySureToDeleteTheItem:
          _vm.$t('allerts.areYouReallySureToDeleteTheItem'),
        close: _vm.$t('allerts.close'),
        confirm: _vm.$t('allerts.confirm'),
        loading: _vm.$t('allerts.loading'),
      }}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }